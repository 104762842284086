import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import { getUserNotifications } from "../../utils/fetchs/userFetchs";
import { useUser } from "../../utils/contexts/userContext";
import Loader from "../../components/Common/Loader/Loader";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import EventCard from "../../components/Entities/Event/EventCard";
import { UserCard } from "../../components/Entities/User/UserCard";
import SingleUserPicture from "../../components/Entities/User/SingleUserPicture";

export default function Notifications() {
  const cssPath = "/assets/css/pages/notifications.css";
  const { user } = useUser();
  const [notifications, setNotifications] = useState([]);

  // Récupérer les notifications de l'utilisateur lors du chargement du composant
  useEffect(() => {
    updateHead({ cssPath });

    if (user) {
      // Appel de l'API pour obtenir les notifications
      getUserNotifications(user)
        .then((data) => {
          setNotifications(data);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des notifications :",
            error
          );
        });
    }
  }, [user]);

  // Fonction pour afficher le type de notification avec un style spécifique
  const renderNotification = (notification) => {
    switch (notification.type) {
      case "sale":
        return (
          <div className="notification sale">
            <p>{notification.message}</p>
          </div>
        );
      case "event":
        return (
          <div className="notification event">
            <EventCard event={notification.data} detailed={false} />
            <p>{notification.message}</p>
          </div>
        );
      case "follow":
        return (
          <div className="notification follow">
            <SingleUserPicture user={notification.data} />
            <p>{notification.message}</p>
          </div>
        );
      case "like":
        return (
          <div className="notification like">
            <SingleUserPicture user={notification.data} />
            <p>{notification.message}</p>
          </div>
        );
      default:
        return (
          <div className="notification">
            <p>{notification.message}</p>
          </div>
        );
    }
  };

  if (!notifications || notifications.length < 0) {
    return <Loader />;
  }

  console.log(notifications);

  return (
    <>
      {updateHead({ cssPath })}
      <BackgroundGradientLur />
      <div className="notifications_page">
        <h2>Notifications</h2>
        {notifications.length === 0 ? (
          <p>Aucune notification pour le moment.</p>
        ) : (
          notifications.map((notification, index) => (
            <div key={index} className="notification_item">
              {renderNotification(notification)}
            </div>
          ))
        )}
      </div>
    </>
  );
}
