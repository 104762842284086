import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { GenericModalContent } from "./GenericModalContent";

export function GenericModal({ text, onConfirm }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cssPath = "assets/css/logout_modal.css";
  const openModal = () => {
    updateHead({ cssPath });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <>
      <FontAwesomeIcon icon={faTrash} className="icon" onClick={openModal} />
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_logout" style={modalTransition}>
            <GenericModalContent
              closeModal={closeModal}
              text={text}
              onConfirm={() => onConfirm()}
            />
          </animated.div>
        </Portal>
      )}
    </>
  );
}
