import React, { useState, useEffect } from "react";
import { useUser } from "../../utils/contexts/userContext";
import updateHead from "../../utils/helpers/updatePageHead";
import AppDownload from "./tmpl/AppDownload";
import ToggleChoice from "./tmpl/ToggleChoice";
import Login from "./Login/LoginContent";
import Register from "./Register/RegisterContent";
import { getImgUrl } from "../../utils/contexts/apiContext";
import SingleUserPicture from "../../components/Entities/User/SingleUserPicture";
import { checkToken } from "../../utils/fetchs/authFetchs";
import { useNavigate } from "react-router-dom";
const api_url = getImgUrl();

export default function LoginRegister() {
  const [showLogin, setShowLogin] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();
  const cssPath = "assets/css/pages/login_register.css";
  const title = showLogin ? "Wive | Se connecter" : "Wive | Créer votre compte";

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      const response = await checkToken(user);
      if (response) {
        navigate("/");
      }
    };
    checkIfLoggedIn();
  }, [user, navigate]);

  return (
    <>
      {updateHead({ title, cssPath })}
      <div className="form_content">
        {showLogin && user && user.email ? (
          <>
            <SingleUserPicture user={user} />
            <p className="hello">Bonjour, {user.username} 👋</p>
          </>
        ) : (
          <img src="assets/images/logo.png" className="logo" alt="Logo" />
        )}
        {showLogin ? <Login /> : <Register />}
        <ToggleChoice showLogin={showLogin} setShowLogin={setShowLogin} />
        <AppDownload />
      </div>
    </>
  );
}
