import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faCalendar,
  faEarthEurope,
  faPaperPlane,
  faCog,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useUser } from "../../../../utils/contexts/userContext";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import { isMobile } from "react-device-detect";
const api_url = getImgUrl();

export default function NavItems(isChatbox) {
  const { user } = useUser();
  const [icon, setIcon] = useState(faComment);

  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(isChatbox);
    if (mobileDisplay) {
      if (isChatbox.isChatbox) {
        setIcon(faCog);
      } else {
        setIcon(faPaperPlane);
      }
    } else {
      setIcon(faComment);
    }
  }, [mobileDisplay, isChatbox]);

  return (
    <>
      <div className="nav_items">
        <>
          {!mobileDisplay && (
            <Link to="/Map">
              <FontAwesomeIcon icon={faEarthEurope} className="icon map" />
            </Link>
          )}
          <Link to="/chatbox">
            <FontAwesomeIcon icon={icon} className="icon" />
          </Link>
          {!mobileDisplay && (
            <Link to="/events">
              <FontAwesomeIcon icon={faCalendar} className="icon" />
            </Link>
          )}
          {!mobileDisplay && (
            <Link to="/notifications">
              <FontAwesomeIcon icon={faBell} className="icon" />
            </Link>
          )}
          {!mobileDisplay && (
            <div className="icon user_profil">
              <Link to={`/${user.username}`}>
                <img
                  src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                  alt="User profile"
                />
              </Link>
            </div>
          )}
        </>
      </div>
    </>
  );
}
