import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import updateHead from "../../../utils/helpers/updatePageHead";
import { BackgroundGradientLur } from "../../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import Loader from "../../../components/Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faRightFromBracket,
  faUsers,
  faPhotoFilm,
  faRepeat,
  faPen,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { translateX100 } from "../../../utils/animations/animations";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import { updateUserInfo } from "../../../utils/fetchs/userFetchs";
import { updateConversation } from "../../../utils/fetchs/conversationFetchs";
import MultipleUserPictures from "../../../components/Entities/User/MultipleUserPictures"; // Import du composant

export default function ConvParams({ activeConv, actualUser }) {
  const cssPath = "/assets/css/conv_params.css";

  const [isModified, setIsModified] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [title, setTitle] = useState(
    generateConversationTitle(activeConv, actualUser)
  );
  const navigate = useNavigate();

  const animation = translateX100();

  useEffect(() => {
    if (activeConv && activeConv.participants) {
      setParticipants(activeConv.participants);
    }
  }, [activeConv]);

  async function handleConversationTitleChange() {
    activeConv.title = title;
    await updateConversation(actualUser, activeConv);
    setIsModified(false);
    navigate("/chatbox");
  }

  async function handleConversationExit() {
    // If 2 people del conv, sinon update
    await updateUserInfo(
      actualUser,
      "conversations",
      actualUser.conversations.filter((convId) => convId !== activeConv._id)
    );
    if (activeConv.participants.length === 1) {
      // del conv
    } else {
      // update conv
      activeConv.participants = activeConv.participants.filter(
        (id) => id !== actualUser._id
      );
      await updateConversation(actualUser, activeConv);
    }
    navigate("/chatbox");
  }

  if (!participants.length) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ cssPath })}
      <BackgroundGradientLur />
      <animated.section style={animation} className="conv_params">
        <div className="header">
          <div className="friends_list_container">
            <MultipleUserPictures users={participants.slice(0, 3)} />{" "}
            {/* Utilisation de MultipleUserPictures */}
          </div>
        </div>
        <div className="friends_list_container">
          {!isModified ? (
            <>
              {generateConversationTitle(activeConv, actualUser)}
              <div className="gradient_btn_outline save">
                <div className="gradient_btn">
                  <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => setIsModified(true)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <input
                className="title"
                placeholder="Titre de la conversation..."
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="gradient_btn_outline save">
                <div className="gradient_btn">
                  <FontAwesomeIcon
                    icon={faSave}
                    onClick={() => handleConversationTitleChange()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="header">
          <p onClick={() => handleConversationExit()}>
            Quitter
            <FontAwesomeIcon icon={faRightFromBracket} className="icon" />
          </p>
        </div>
        <div className="params">
          {(activeConv.type === "private" ||
            activeConv.authorizedSenders.includes(actualUser._id)) && (
            <p>
              <FontAwesomeIcon icon={faUsers} className="icon" />
              Gérer les membres
            </p>
          )}
          <p>
            <FontAwesomeIcon icon={faBell} className="icon" />
            Mettre en sourdine
          </p>
        </div>
        <div className="medias">
          <FontAwesomeIcon icon={faRepeat} className="icon" />
          <FontAwesomeIcon icon={faPhotoFilm} className="icon" />
        </div>
      </animated.section>
    </>
  );
}
