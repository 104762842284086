import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

export function GenericModalContent({ closeModal, text, onConfirm }) {
  return (
    <>
      <p>{text}</p>
      <button className="logout_yes" onClick={() => onConfirm()}>
        oui <FontAwesomeIcon icon={faCheck} />
      </button>
      <button className="logout_no" onClick={closeModal}>
        non <FontAwesomeIcon icon={faXmark} />
      </button>
    </>
  );
}
