import React, { useState, useEffect } from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import Switch from "../../../components/Common/Buttons/Switch";
import CreateListModal from "../../../components/Modals/CreateListModal/CreateListModal";
import { getListByUserId, createList } from "../../../utils/fetchs/listFetchs";
import DiscordServerCard from "../tmpl/DiscordServerCard";

export default function DiscordConnexion({
  event,
  setEvent,
  discordServer,
  setDiscordServer,
}) {
  const [inviteLink, setInviteLink] = useState(false);

  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [availableLists, setAvailableLists] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    async function fetchLists() {
      const lists = await getListByUserId(user);
      setAvailableLists(lists);
    }
    fetchLists();
  }, [user]);

  useEffect(() => {
    // Vérifiez si generate_discord_link existe et définissez-le à "public" si ce n'est pas le cas
    if (!event.generate_discord_link) {
      setEvent((prev) => ({
        ...prev,
        generate_discord_link: false,
      }));
    }
  }, [event, setEvent]);

  const updateEventSettings = (field, value) => {
    setEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field === "generate_discord_linkList" && value === "createList") {
      setShowCreateListModal(true);
    }
  };

  const handleCreateList = async (listData) => {
    const newList = await createList(user, listData);
    setAvailableLists((prevLists) => [...prevLists, newList]);
    updateEventSettings("generate_discord_linkList", newList._id); // Sélectionner la nouvelle liste
    setShowCreateListModal(false);
  };

  return (
    <div className="discord_col">
      <h2 className="text_gradient second">Lier un serveur Discord</h2>
      {discordServer ? (
        <>
          <p>Votre serveur :</p>
          <DiscordServerCard discordServer={discordServer} hasJoined={true} />
          <Switch
            label={"Générer un lien d'invitation"}
            value={inviteLink}
            setValue={() => setInviteLink(!inviteLink)}
          />
          {inviteLink && (
            <>
              <label className="inline">
                Visibilité de l'invitation :
                <select
                  value={event.generate_discord_link}
                  onChange={(e) =>
                    updateEventSettings("generate_discord_link", e.target.value)
                  }
                >
                  <option value="public">Tout le monde</option>
                  <option value="participants">Participants uniquement</option>
                  <option value="list">Certains participants uniquement</option>
                </select>
              </label>
              {event.generate_discord_link === "list" && (
                <div className="ticket_flex_between">
                  <label>
                    Participants autorisés :
                    <select
                      value={event.generate_discord_linkList}
                      onChange={(e) =>
                        updateEventSettings(
                          "generate_discord_link",
                          e.target.value
                        )
                      }
                    >
                      <option value="select">-Sélectionner une liste-</option>
                      {availableLists.map((list) => (
                        <option key={list._id} value={list._id}>
                          {list.name}
                        </option>
                      ))}
                      <option value="createList">Créer une liste</option>
                    </select>
                  </label>
                  <GradientButton
                    text={"Créer une liste"}
                    icon={faPlus}
                    onClick={() => setShowCreateListModal(true)}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="event_invite">
          <p>Serveur à lier :</p>
          <GradientButton
            text={"Ouvrir Discord"}
            icon={faDiscord}
            link={
              "https://discord.com/oauth2/authorize?client_id=1289565457174757439&permissions=580931103370353&response_type=code&redirect_uri=https%3A%2F%2Fwive.fr%2Fdiscord&integration_type=0&scope=identify+guilds+guilds.members.read+guilds.channels.read+bot"
            }
          />
        </div>
      )}
      {showCreateListModal && (
        <CreateListModal
          discordRoles={discordServer.roles}
          tickets={tickets}
          createdFor={event._id}
          onSave={handleCreateList}
          shouldBeOpen={showCreateListModal}
          setShouldBeOpen={setShowCreateListModal}
        />
      )}
    </div>
  );
}
