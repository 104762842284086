import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import updateHead from "../../utils/helpers/updatePageHead";
import Loader from "../../components/Common/Loader/Loader";
import TicketCol from "./Editing/TicketEditing";
import PublicationCol from "./Editing/PublicationEditing";
import CreaEventCard from "../../components/Entities/Event/CreaEventCard/CreaEventCard";
import ConversationCol from "./Editing/ConversationEditing";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getEventById } from "../../utils/fetchs/eventFetchs";
import { useUser } from "../../utils/contexts/userContext";
import MenuCol from "./tmpl/MenuCol";
import { getPublicationsFromEventId } from "../../utils/fetchs/publicationFetchs";
import InviteUser from "./Editing/InviteUser";
import StepCol from "./Editing/StepEditing";
import { getStepsFromEventId } from "../../utils/fetchs/stepFetchs";
import { isMobile } from "react-device-detect";
import { MenuContext } from "../../utils/contexts/menuContext";
import DiscordConnexion from "./Editing/DiscordConnexion";
import { getEventDiscordServer } from "../../utils/fetchs/discordFetchs";
import { useEvent } from "../../utils/contexts/eventContext";
import TwitchConnexion from "./Editing/TwitchConnexion";

export default function EventStudio() {
  const cssPath = "/assets/css/pages/event_studio.css";
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const { setEvent: setContextEvent } = useEvent();
  const { eventId } = useParams();
  const { user, token } = useUser();
  const [event, setEvent] = useState(null);
  const [publications, setPublications] = useState(null);
  const [discordServer, setDiscordServer] = useState(null);
  const [tickets, setTickets] = useState([
    {
      event_id: eventId,
      name: "",
      category: "",
      description: "",
      quantity: 0,
      price: 0,
      start_date: "",
      end_date: "",
      image: "",
      ageRestricted: false,
      isEditing: true,
      product_id: "",
      price_id: "",
      _id: "",
    },
  ]);

  const [generalStepsContent, setGeneralStepsContent] = useState({
    _id: null,
    event_id: eventId,
    title: "",
    map: false,
    permissions: "public",
    list: null,
    steps: [],
  });
  const [isModifying, setIsModifying] = useState(true);

  const ticketRef = useRef(null);
  const inviteRef = useRef(null);
  const publicationRef = useRef(null);
  const eventRef = useRef(null);
  const stepRef = useRef(null);
  const connexionRef = useRef(null);
  const merchRef = useRef(null);
  const twitchRef = useRef(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const data = await getEventById(eventId);
        if (data) {
          setContextEvent(data);
          setEvent(data);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération de l'événement :", err);
      }
    };

    const fetchDiscordServer = async () => {
      try {
        if (event?.discordServerId) {
          const data = await getEventDiscordServer(eventId, user);
          setDiscordServer(data || null);
        }
      } catch (err) {
        console.error(
          "Erreur lors de la récupération du serveur Discord :",
          err
        );
      }
    };

    fetchEvent().then(() => {
      fetchDiscordServer();
    });
  }, [eventId, user]);

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        if (event?.steps && event.steps.length > 0) {
          const stepsData = await getStepsFromEventId(user, eventId);
          setGeneralStepsContent(stepsData || generalStepsContent);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des étapes :", error);
      }
    };

    const fetchPublications = async () => {
      try {
        const publicationsData = await getPublicationsFromEventId(eventId);
        setPublications(publicationsData || []);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des publications :",
          error
        );
      }
    };

    if (event) {
      fetchSteps();
      fetchPublications();
    }
  }, [event, eventId, user]);

  const conversationRef = useRef(null);
  const { menuOpen, setMenuOpen } = useContext(MenuContext);

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setIsPhone(isMobile || window.innerWidth < 767.98);
      setMenuOpen(menuOpen || window.innerWidth > 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!event || !event._id) {
    return <Loader />;
  }

  if (!user || !user._id) {
    return (window.location = "/login-register?tk=true");
  }

  if (event && event.organiser_id._id !== user._id) {
    return (window.location = "/");
  }

  // Set page title
  const title = `Wive | ${event.title}`;
  console.log(event, publications);
  return (
    <>
      {updateHead({ title, cssPath })}
      <BackgroundGradientLur />
      <main>
        <MenuCol
          event={event}
          eventRef={eventRef}
          publicationRef={publicationRef}
          ticketRef={ticketRef}
          conversationRef={conversationRef}
          inviteRef={inviteRef}
          stepRef={stepRef}
          connexionRef={connexionRef}
          merchRef={merchRef}
          twitchRef={twitchRef}
        />
        {(!isPhone || (isPhone && !menuOpen)) && (
          <section id="studio">
            <div className="studio_container">
              <div className="left_col">
                <div ref={eventRef}>
                  <CreaEventCard event={event} />
                </div>
                <div ref={publicationRef}>
                  <PublicationCol
                    publications={publications}
                    setPublications={setPublications}
                    event={event}
                  />
                </div>
              </div>
              <div className="right_col">
                <div ref={inviteRef}>
                  <InviteUser event={event} />
                </div>
                <div ref={conversationRef}>
                  <ConversationCol event={event} user={user} />
                </div>
                <div ref={ticketRef}>
                  <TicketCol
                    event={event}
                    tickets={tickets}
                    setTickets={setTickets}
                  />
                </div>
              </div>
            </div>
            <div ref={stepRef}>
              <StepCol
                generalStepsContent={generalStepsContent}
                setGeneralStepsContent={setGeneralStepsContent}
                discordServer={discordServer}
                tickets={tickets}
                event={event}
              />
            </div>
            <div ref={connexionRef}>
              <DiscordConnexion
                event={event}
                setEvent={setEvent}
                discordServer={discordServer}
                setDiscordServer={setDiscordServer}
              />
            </div>
            <div ref={twitchRef}>
              <TwitchConnexion event={event} />
            </div>

            <div ref={merchRef}></div>
          </section>
        )}
      </main>
    </>
  );
}
