import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import {
  createTicket,
  deleteTicketFromId,
  getTicketsFromEventId,
  updateTicket,
} from "../../../utils/fetchs/ticketFetchs";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import TicketSlider from "../../EventPage/tmpl/TicketSlider";
import Switch from "../../../components/Common/Buttons/Switch";

export default function TicketCol({ event, tickets, setTickets }) {
  const fileInputRefs = useRef([]);
  const [ageRestriction, setAgeRestriction] = useState(false);
  const [categories, setCategories] = useState(["Selectionner une catégorie"]);

  useEffect(() => {
    if (event) {
      getTicketsFromEventId(event._id).then((tickets) => setTickets(tickets));
    }
  }, [event]);

  const handleImageChange = (index, e) => {
    const newTickets = [...tickets];
    newTickets[index].image = e.target.files[0];
    setTickets(newTickets);
  };

  const triggerFileInput = (index) => {
    fileInputRefs.current[index].click();
  };

  const handleEditTicket = (index) => {
    const newTickets = [...tickets];
    newTickets[index].isEditing = true;
    setTickets(newTickets);
  };

  const handleTicketChange = (index, field, value) => {
    if (index >= 0 && index < tickets.length) {
      const newTickets = [...tickets];
      const ticket = newTickets[index];
      if (ticket) {
        ticket[field] = value;
        ticket.isEditing = true;
        setTickets(newTickets);
      } else {
        console.error("Ticket non trouvé à l'index", index);
      }
    } else {
      console.error("Index invalide", index);
    }
  };

  const handleSaveTicket = async (index) => {
    const newTickets = [...tickets];
    const ticket = newTickets[index];
    if (ticket.image) {
      ticket.image = await handleUpload(ticket.image, index);
    }

    newTickets[index] = !ticket._id
      ? await createTicket(ticket)
      : await updateTicket(ticket._id, ticket);

    setTickets(newTickets);
  };

  const deleteTicket = (index) => {
    const ticket = tickets[index];
    if (ticket._id) {
      deleteTicketFromId(ticket);
    }

    const newTickets = tickets.filter((_, i) => i !== index);
    newTickets
      ? setTickets(newTickets)
      : setTickets([
          {
            event_id: event._id,
            name: "",
            category: "",
            description: "",
            quantity: 0,
            price: 0,
            start_date: "",
            end_date: "",
            image: "",
            ageRestricted: false,
            isEditing: true,
            product_id: "",
            price_id: "",
            _id: "",
          },
        ]);
  };

  return (
    <>
      <div className="tickets_col">
        <h2 className="text_gradient first">Billets</h2>
        <TicketSlider
          tickets={tickets.filter((ticket) => !ticket.isEditing)}
          event={event}
          onReservation={handleEditTicket}
        />
        {tickets.map((ticket, index) => (
          <div key={index}>
            {ticket && ticket.isEditing ? (
              <div key={index} className="ticket">
                <input
                  type="text"
                  value={ticket.name}
                  onChange={(e) =>
                    handleTicketChange(index, "name", e.target.value)
                  }
                  placeholder="Nom du billet"
                />

                <div className="ticket_flex">
                  <label>
                    Date et heure de validité :
                    <input
                      type="datetime-local"
                      value={ticket.start_date}
                      onChange={(e) =>
                        handleTicketChange(index, "start_date", e.target.value)
                      }
                    />
                  </label>

                  <label>
                    Date et heure de fin :
                    <input
                      type="datetime-local"
                      value={ticket.end_date}
                      onChange={(e) =>
                        handleTicketChange(index, "end_date", e.target.value)
                      }
                    />
                  </label>
                </div>
                <Switch
                  label="Réservé aux 18 ans et plus :"
                  value={ageRestriction}
                  setValue={() => setAgeRestriction(!ageRestriction)}
                  tooltip="Seulement les utilisateurs majeurs ayant vérifié leur identité pourront rejoindre votre événement."
                />
                <div className="image_container">
                  {ticket.image ? (
                    <img
                      src={ticket.image}
                      alt="Ticket Thumbnail"
                      className="picture"
                      onClick={() => triggerFileInput(index)}
                    />
                  ) : (
                    <button onClick={() => triggerFileInput(index)}>
                      <FontAwesomeIcon icon={faImage} /> Ajouter une image
                    </button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    ref={(el) => (fileInputRefs.current[index] = el)}
                    onChange={(e) => handleImageChange(index, e)}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="ticket_flex">
                  <label>
                    Prix :
                    <input
                      type="number"
                      value={ticket.price}
                      onChange={(e) =>
                        handleTicketChange(index, "price", e.target.value)
                      }
                      placeholder="Prix"
                    />
                  </label>
                  <input
                    type="number"
                    value={ticket.quantity || ""}
                    onChange={(e) =>
                      handleTicketChange(index, "quantity", e.target.value)
                    }
                    placeholder="Quantité disponible"
                  />
                </div>
                <select
                  value={ticket.category || ""}
                  onChange={(e) => {
                    if (e.target.value === "add_new") {
                      const newCategory = prompt(
                        "Entrez le nom de la nouvelle catégorie:"
                      );
                      if (newCategory) {
                        setCategories([...categories, newCategory]);
                        handleTicketChange(index, "category", newCategory);
                      }
                    } else {
                      handleTicketChange(index, "category", e.target.value);
                    }
                  }}
                >
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                  <option value="add_new">
                    Ajouter une nouvelle catégorie
                  </option>
                </select>

                <textarea
                  value={ticket.description}
                  onChange={(e) =>
                    handleTicketChange(index, "description", e.target.value)
                  }
                  placeholder="Description du billet"
                />
                {ticket.isEditing && (
                  <div className="ticket_flex sm">
                    <GradientButton
                      type={"validate"}
                      onClick={() => handleSaveTicket(index)}
                    />
                    <GradientButton
                      type={"delete"}
                      onClick={() => deleteTicket(index)}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ))}

        <GradientButton
          icon={faPlus}
          text={"Ajouter un billet"}
          padding={"10px"}
          onClick={() =>
            setTickets([
              ...tickets,
              {
                event_id: event._id,
                name: "",
                category: "",
                description: "",
                quantity: 0,
                price: 0,
                start_date: "",
                end_date: "",
                image: "",
                ageRestricted: false,
                isEditing: true,
                product_id: "",
                price_id: "",
                _id: "",
              },
            ])
          }
        />
      </div>
    </>
  );
}
