import React, { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkTwitchUser } from "../../../utils/fetchs/twitchFetchs";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";

export default function TwitchConnexion({ event, onChannelLinked }) {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLinkTwitch = async () => {
    try {
      setLoading(true);
      setError(null);

      // Démarrer le flux OAuth2 de Twitch
      const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/twitch/callback&response_type=code&scope=user:read:email`;

      // Rediriger l'utilisateur vers le flux Twitch
      window.location.href = twitchAuthUrl;
    } catch (err) {
      console.error(err);
      setError("Une erreur est survenue lors de la liaison avec Twitch.");
    } finally {
      setLoading(false);
    }
  };

  const handleUnlinkTwitch = async () => {
    try {
      setLoading(true);
      setError(null);

      // Appeler l'API pour dissocier la chaîne Twitch
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/twitch/unlink`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ userId: user._id, eventId: event._id }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to unlink Twitch channel");
      }

      const result = await response.json();
      console.log("Twitch channel unlinked:", result);

      // Notifier le parent ou rafraîchir l'état
      onChannelLinked(null);
    } catch (err) {
      console.error(err);
      setError("Impossible de dissocier la chaîne Twitch.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="twitch-connexion">
      <h2 className="text_gradient second">Lier Twitch</h2>
      {event.twitch_channel ? (
        <div className="twitch-linked">
          <p>Chaîne Twitch : {event.twitch_channel}</p>
          <GradientButton
            text="Dissocier"
            icon={faTrash}
            onClick={handleUnlinkTwitch}
            disabled={loading}
          />
        </div>
      ) : (
        <div className="event_invite">
          <FontAwesomeIcon icon={faTwitch} className="twitch-btn" />
          <GradientButton
            text="Lier ma chaîne Twitch"
            onClick={handleLinkTwitch}
            disabled={loading}
          />
          {error && <p className="error-text">{error}</p>}
        </div>
      )}
    </div>
  );
}
