import { Link } from "react-router-dom";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { getProfilePictureUrl } from "../../../utils/contexts/apiContext"; // Import de la fonction

const api_url = getImgUrl();

export default function SingleUserPicture({ user }) {
  const profile_picture_url = user
    ? getProfilePictureUrl(user, api_url)
    : `${api_url}/uploads/profilepics/ppplaceholder.jpeg`;

  const username = user?.username || "unknown";

  return (
    <Link className="pp_wrapper" to={`/${username}`}>
      <img
        src={profile_picture_url}
        alt="profile_picture"
        className="chatbox_sender_pp chatbox_cover"
      />
    </Link>
  );
}
