import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function createUser(userData) {
  try {
    const response = await fetch(`${api_url}/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const element = await response.json();
    switch (response.status) {
      case 201:
        return element;
      case 400:
        return null;
      default:
        throw new Error("Unable to create user");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getAllUsernames() {
  try {
    const response = await fetch(`${api_url}/user/usernames`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 404:
        return [];
      default:
        throw new Error("Unable to retreive all usernames");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getUserByUsername(username) {
  try {
    const response = await fetch(`${api_url}/user/nick/${username}`, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        const sessionInfos = await response.json();
        const userData = sessionInfos.user;
        return userData;
      case 404:
        return null;
      default:
        throw new Error(
          `Unable to retreive user form this username : ${username}`
        );
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getUserByUserId(userId) {
  try {
    const response = await fetch(`${api_url}/user/${userId}`, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        const sessionInfos = await response.json();
        const userData = sessionInfos.user;
        return userData;
      default:
        throw new Error(
          `Unable to retreive user form this user ID : ${userId}`
        );
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getProfileFeed(user) {
  try {
    const response = await fetch(`${api_url}/user/feed/${user._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 404:
        return [];
      default:
        throw new Error("Unable to retreive all usernames");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function updateUserInfo(loggedInUser, name, value) {
  try {
    if (loggedInUser._id) {
      const tk = await loggedInUser.token;
      const response = await fetch(`${api_url}/user/update`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tk,
        },
        body: JSON.stringify({
          userId: loggedInUser._id,
          update: { [name]: value },
        }),
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;

        default:
          throw new Error(
            `Impossible de mettre à jour l'utilisateur avec l'ID : ${loggedInUser._id}`
          );
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getUserNotifications(user) {
  try {
    if (user._id) {
      const tk = await user.token;
      const response = await fetch(
        `${api_url}/user/notifications/${user._id}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tk,
          },
        }
      );

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error(
            `Unable to retreive conversation with id : ${conversationId}`
          );
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}
