import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function createTicket(ticketData) {
  try {
    const response = await fetch(`${api_url}/ticket/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticketData),
    });

    switch (response.status) {
      case 201:
        return await response.json();
      default:
        throw new Error("Unable to create ticket");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function updateTicket(ticketId, ticket) {
  try {
    const response = await fetch(`${api_url}/ticket/update/${ticketId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticket),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to update ticket");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function deleteTicketFromId(ticket) {
  try {
    const response = await fetch(`${api_url}/ticket/delete"`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket }),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to delete ticket");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getTicketsFromEventId(eventId) {
  try {
    const response = await fetch(`${api_url}/ticket/event/${eventId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to fetch tickets of the event");
    }
  } catch (error) {
    console.error(error.message);
  }
}
