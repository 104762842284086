import React, { useState, useEffect } from "react";
import EventsCalendar from "./tmpl/EventsCalendar";
import Loader from "../../components/Common/Loader/Loader";
import updateHead from "../../utils/helpers/updatePageHead";
import OrganizedEvents from "./tmpl/OrganizedEvents";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getEventsFromUserId } from "../../utils/fetchs/eventFetchs";
import { useUser } from "../../utils/contexts/userContext";
import ParticipatingsEvents from "./tmpl/ParticipatingEvents";

export default function PersonalEvents() {
  const cssPath = "/assets/css/pages/personal_events.css";
  const title = "Wive | Vos événements";

  const { user } = useUser();
  const [events, setEvents] = useState(null);

  useEffect(() => {
    getEventsFromUserId(user).then((events) => setEvents(events));
  }, [user]);

  if (!events) {
    return <Loader />;
  }

  // events are only the one where event.organiser_id === user._id, change controller for something else
  const eventsDates = events.map((event) => new Date(event.date));
  const userEvents = events.filter(
    (event) =>
      Array.isArray(event.participants) &&
      event.participants.includes(user.username)
  );

  const userOrganizedEvents = events.filter(
    (event) => event.organiser_id === user._id
  );

  return (
    <>
      {updateHead({ title, cssPath })}
      <BackgroundGradientLur />

      <div className="personal_events">
        <EventsCalendar eventsDates={eventsDates} />
        <ParticipatingsEvents events={userOrganizedEvents} />
      </div>

      <OrganizedEvents events={userOrganizedEvents} />
    </>
  );
}
