import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { faTimes, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../Common/Buttons/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../../utils/contexts/userContext";
import { getFollowings } from "../../../utils/fetchs/followRequestFetchs";
import { UserCard } from "../../Entities/User/UserCard"; // Import de UserCard

export function UserList({
  userList,
  buttonAction, // action to perform when button is clicked (optional)
  shouldBeOpen,
  setShouldBeOpen,
  title, // the title passed to display the correct context
}) {
  const { user } = useUser();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const cssPath = "/assets/css/components/user_list.css"; // Set the CSS to import

  const isLoggedIn = user && user.email;

  useEffect(() => {
    const fetchFollowings = async () => {
      const followings = await getFollowings(user, user._id);
      setFollowingList(followings);
    };

    fetchFollowings();
  }, [user._id]);

  useEffect(() => {
    setUsers(userList);
    setFilteredUsers(userList);
  }, [userList]);

  useEffect(() => {
    if (shouldBeOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [shouldBeOpen]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const handleSearch = (value) => {
    setFilteredUsers(
      users.filter((user) =>
        user.username.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };

  const isFollowing = (userId) => {
    return followingList.some((following) => following._id === userId);
  };

  const handleButtonClick = async (userId) => {
    await buttonAction(userId);
    // Refresh following list after button action to reflect updated state
    const followings = await getFollowings(user, user._id);
    setFollowingList(followings);
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <button className="close_modal_button" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {isLoggedIn ? (
              <>
                <div className="modal_follow_title_container">
                  <h2>{title}</h2>
                </div>
                <input
                  className="follow_search"
                  type="text"
                  placeholder="Rechercher"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <ul className="modal_follow_list">
                  {filteredUsers.map((user) => (
                    <li key={user._id}>
                      <UserCard
                        user={user}
                        link={true}
                        subText={user.description}
                      />
                      {buttonAction && (
                        <GradientButton
                          icon={isFollowing(user._id) ? faTrash : faPlus}
                          round={true}
                          onClick={() => handleButtonClick(user._id)}
                          label={""}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="login">
                <p>
                  Connectez-vous pour avoir accès aux fonctionnalités de Wive.
                </p>
                <GradientButton type={"login"} width={"60%"} />
              </div>
            )}
          </animated.div>
        </Portal>
      )}
    </>
  );
}
