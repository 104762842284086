import { useNavigate } from "react-router-dom";
import {
  getImgUrl,
  getProfilePictureUrl,
} from "../../../utils/contexts/apiContext";

const api_url = getImgUrl();

export default function MultipleUserPictures({ users }) {
  const navigate = useNavigate();

  const filteredUsers = users.map((user) => ({
    ...user,
    profilepic: getProfilePictureUrl(user, api_url),
    username: user.username,
  }));

  return (
    <>
      {filteredUsers.map((user) => (
        <img
          key={user._id}
          src={user.profilepic}
          alt="profile_picture"
          className="chatbox_sender_pp chatbox_cover"
          onClick={() => navigate(`/${user.username}`)}
        />
      ))}
    </>
  );
}
