import { useState, useEffect } from "react";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { sendMessage } from "../../../utils/fetchs/messagesFetchs";
import { getUserConversations } from "../../../utils/fetchs/conversationFetchs";
import Loader from "../../../components/Common/Loader/Loader";
import MultipleUserPictures from "../../../components/Entities/User/MultipleUserPictures";
import updateHead from "../../../utils/helpers/updatePageHead";

export default function ShareEventList({ user, event }) {
  const [conversations, setConversations] = useState(null);
  const cssPath = "/assets/css/components/share_message.css";

  useEffect(() => {
    const fetchData = async () => {
      const converations = await getUserConversations(user);
      setConversations(
        converations.sort((c1, c2) => c1.lastUpdated > c2.lastUpdated)
      );
    };

    fetchData();
  }, []);

  function retrieveParticipants(conversation) {
    return conversation.participants.filter((p) => p._id !== user._id);
  }

  async function shareEvent() {
    // Implémentation de la logique de partage de l'événement
    console.log("Partager l'événement !");
  }

  if (!conversations) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ cssPath })}
      <div className="hrwrapper">
        <p>Partagez-le avec vos amis :</p>
        <div className="hrgradient"></div>
      </div>
      <ul className="share_event_list_container">
        {conversations.map(
          (conv, index) =>
            index < 3 && (
              <li key={conv._id}>
                <div className="share_to_conv_block">
                  <div className="user">
                    <MultipleUserPictures
                      users={retrieveParticipants(conv).slice(0, 2)}
                    />
                    <p>{generateConversationTitle(conv, user)}</p>
                  </div>

                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="icon"
                    onClick={shareEvent}
                  />
                </div>
              </li>
            )
        )}
      </ul>
    </>
  );
}
