import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import { displayEventDate } from "../../../utils/helpers/eventHelper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { translateYMinus100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import Loader from "../../../components/Common/Loader/Loader";
import TicketSlider from "../../EventPage/tmpl/TicketSlider";
import { getSalesByUserId } from "../../../utils/fetchs/saleFetch";
import { useUser } from "../../../utils/contexts/userContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import EventCard from "../../../components/Entities/Event/EventCard";
import GradientButton from "../../../components/Common/Buttons/GradientButton";

const api_url = getImgUrl();

export default function ParticipatingEvents() {
  const topAnimation = translateYMinus100();
  const { user } = useUser();
  const [tickets, setTickets] = useState([]);
  const [events, setEvents] = useState({});
  const [loadingTickets, setLoadingTickets] = useState(true);

  useEffect(() => {
    const fetchTicketsAndEvents = async () => {
      try {
        const userTickets = await getSalesByUserId(user, user._id);
        const eventData = {};

        for (const ticket of userTickets) {
          if (ticket.billet && ticket.billet.length > 0) {
            const billet = ticket.billet[0];
            const eventId = billet.event_id;
            if (!eventData[eventId]) {
              const event = await getEventById(eventId);
              eventData[eventId] = event;
            }
          }
        }

        setTickets(userTickets);
        setEvents(eventData);
      } catch (error) {
        console.error("Error fetching tickets or events:", error);
      } finally {
        setLoadingTickets(false);
      }
    };
    fetchTicketsAndEvents();
  }, [user]);

  if (loadingTickets) {
    return <Loader />;
  }

  return (
    <animated.div style={topAnimation} className="participant_events_section">
      <ul>
        {tickets.map((ticket) => {
          if (ticket.billet && ticket.billet.length > 0) {
            const billet = ticket.billet[0];
            const event = events[billet.event_id];
            if (!event) {
              return null;
            }
            return (
              <li key={ticket._id} className="ticket_container">
                <p className="text_gradient">Événement :</p>
                <EventCard event={event} />
                <TicketSlider
                  event={event}
                  tickets={[ticket]}
                  loggedInUserIsParticipating={ticket.billet[0]}
                />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </animated.div>
  );
}
