import React from "react";
import {
  faPager,
  faPenToSquare,
  faTicket,
  faMessage,
  faChartSimple,
  faUserPlus,
  faThumbTack,
  faLink,
  faShirt,
} from "@fortawesome/free-solid-svg-icons";
import { calculateCompletion } from "../../../utils/helpers/userHelper";
import LeftColMenu from "../../../components/Common/LeftColMenu/LeftColMenu";
import { faDiscord, faTwitch } from "@fortawesome/free-brands-svg-icons";

export default function MenuCol({
  event,
  eventRef,
  publicationRef,
  ticketRef,
  conversationRef,
  inviteRef,
  stepRef,
  connexionRef,
  merchRef,
  twitchRef,
}) {
  const scrollToRef = (ref, applyOffset = false) => {
    if (ref.current) {
      const navbarHeight = applyOffset ? 100 : 0;
      const offsetTop = ref.current.offsetTop - navbarHeight;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const items = [
    {
      icon: faPager,
      label: "Événement",
      percentage: calculateCompletion(event),
      onClick: () => scrollToRef(eventRef),
    },
    {
      icon: faPenToSquare,
      label: "Publications",
      percentage: calculateCompletion(event.publications),
      onClick: () => scrollToRef(publicationRef),
    },
    {
      icon: faUserPlus,
      label: "Invités",
      percentage: calculateCompletion(event),
      onClick: () => scrollToRef(inviteRef),
    },
    {
      icon: faMessage,
      label: "Conversation",
      percentage: calculateCompletion(event.conversations),
      onClick: () => scrollToRef(conversationRef),
    },
    {
      icon: faTicket,
      label: "Billets",
      percentage: calculateCompletion(event.billets),
      onClick: () => scrollToRef(ticketRef, true),
    },

    {
      icon: faThumbTack,
      label: "Étapes",
      percentage: calculateCompletion(event.publications),
      onClick: () => scrollToRef(stepRef),
    },
    {
      icon: faDiscord,
      label: "Discord",
      onClick: () => scrollToRef(connexionRef),
    },
    {
      icon: faTwitch,
      label: "Twitch",
      onClick: () => scrollToRef(twitchRef),
    },
    {
      icon: faShirt,
      label: "Merchandising",
      onClick: () => scrollToRef(merchRef),
    },
    {
      icon: faChartSimple,
      label: "Statistiques d'événement",
      onClick: () => scrollToRef(eventRef),
    },
  ];

  return (
    <LeftColMenu
      items={items}
      dividerClass="hrgradient"
      pageTitle={"Paramètres d'événement"}
    />
  );
}
