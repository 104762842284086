import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Common/Loader/Loader";
import MultipleUserPictures from "../../../components/Entities/User/MultipleUserPictures";
import SingleUserPicture from "../../../components/Entities/User/SingleUserPicture";
import { getMessagesByConversationId } from "../../../utils/fetchs/messagesFetchs";
import { useSocket } from "../../../utils/contexts/socketContext";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export function ChatboxConversation({
  conversation,
  actualUser,
  showChat,
  setShowChat,
  activeConv,
  handleActiveConv,
  sharedPubli,
}) {
  const [lastMsg, setLastMsg] = useState(null);
  const [event, setEvent] = useState(null);

  const [nbrNewMsg, setNbrNewMsg] = useState(0);
  const socket = useSocket();
  const { setSharedPubli, setShowConvFirst } = useContext(ChatboxContext);
  const navigate = useNavigate();

  useEffect(() => {
    socket.emit("joinConversation", conversation._id);
    socket.on("message", (messageData) => {
      if (conversation._id === messageData.conversationId) {
        setLastMsg(messageData.message);
        if (activeConv) {
          conversation._id === activeConv._id
            ? setNbrNewMsg(0)
            : setNbrNewMsg((n) => n + 1);
        }
      }
    });

    return () => {
      socket.emit("leaveConversation", conversation._id);
    };
  }, [socket, conversation._id]);

  useEffect(() => {
    if (conversation) {
      getMessagesByConversationId(conversation._id).then((messages) => {
        setLastMsg(messages[messages.length - 1]);
        setNbrNewMsg(
          messages.filter(
            (m) => m.read === false && m.senderId !== actualUser._id
          ).length
        );
      });
      if (conversation.type === "event") {
        getEventById(conversation.event_id).then((eventData) => {
          setEvent(eventData);
        });
      }
    }
  }, [conversation]);

  function handleConversationClick() {
    if (sharedPubli) {
      setSharedPubli(sharedPubli);
      handleActiveConv(conversation);
      setShowConvFirst(true);
      navigate("/chatbox");
    } else {
      if (showChat == false) {
        setShowChat(true);
      }
      setNbrNewMsg(0);
      handleActiveConv(conversation);
    }
  }

  if (
    !conversation.participants ||
    (conversation.type.includes("event") && !event)
  ) {
    return <Loader />;
  }
  console.log(lastMsg);
  return (
    <>
      <article
        className={`chatbox_block ${conversation.type}`}
        onClick={() => handleConversationClick()}
        style={{
          width: showChat ? "" : "100%",
          padding: showChat ? "" : "10px",
        }}
      >
        {activeConv ? (
          <div
            className={
              conversation._id === activeConv._id
                ? "chatbox_block_background active"
                : "chatbox_block_background"
            }
          ></div>
        ) : null}

        {(() => {
          switch (conversation.type) {
            case "private":
              return (
                <SingleUserPicture
                  user={conversation.participants.find(
                    (p) => p._id !== actualUser._id
                  )}
                />
              );
            case "group":
              return (
                <div className="chatbox_sender_pp_container">
                  <MultipleUserPictures
                    users={conversation.participants
                      .filter((p) => p._id !== actualUser._id)
                      .slice(0, 2)}
                  />
                </div>
              );
            case "event":
              return (
                <div className="chatbox_event_container">
                  {event && event.picture ? (
                    <img
                      src={`${api_url}/uploads/events/${event.picture}`}
                      className="post_image"
                      alt="publication"
                    />
                  ) : (
                    <div className="chatbox_sender_pp_container">
                      <MultipleUserPictures
                        users={conversation.participants
                          .filter((p) => p._id !== actualUser._id)
                          .slice(0, 2)}
                      />
                    </div>
                  )}
                </div>
              );
            default:
              return null;
          }
        })()}
        <div className="chatbox_details">
          <div className="chatbox_listhead">
            <p>{generateConversationTitle(conversation, actualUser)}</p>
            {lastMsg && lastMsg.timestamp ? (
              <p className="time">{dayjs(lastMsg.timestamp).format("HH:mm")}</p>
            ) : (
              <p className="time">--:--</p>
            )}
          </div>
          <div className="chatbox_flex event_conv_message_wrapper">
            {conversation.type === "event" &&
              event.picture &&
              lastMsg &&
              lastMsg.senderId && (
                <SingleUserPicture
                  user={conversation.participants.find(
                    (participant) => participant._id === lastMsg.senderId._id
                  )}
                />
              )}
            <div className="chatbox_message">
              {lastMsg ? (
                <p>
                  {lastMsg.sharedEventId
                    ? `${
                        lastMsg.senderId._id === actualUser._id
                          ? "Vous avez"
                          : "Vous a"
                      } partagé un événement`
                    : lastMsg.content || "🦉 Ça paraît bien vide ici... 🦉"}
                </p>
              ) : (
                <p>🦉 Ça paraît bien vide ici... 🦉</p>
              )}

              <div>{nbrNewMsg === 0 ? "" : <b>{nbrNewMsg}</b>}</div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
