export function getApiUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr/api"
    : "http://localhost:8080/api";
}

export function getImgUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr"
    : "http://localhost:8080";
}

export function getDiscordUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://discord.com/oauth2/authorize?client_id=1289565457174757439&response_type=code&redirect_uri=https%3A%2F%2Fwive.fr%2Fdiscord&scope=identify+email"
    : "https://discord.com/oauth2/authorize?client_id=1289565457174757439&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%2Fdiscord&scope=identify+email";
}
export function getTwitchUrl() {
  return process.env.NODE_ENV === "production"
    ? `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=https://wive.fr/twitch&scope=channel:manage:polls+channel:read:polls&state=c3ab8aa609ea11e793ae92361f002671`
    : `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=http://localhost:3000/twitch&scope=channel:manage:polls+channel:read:polls&state=c3ab8aa609ea11e793ae92361f002671`;
}

export function getProfilePictureUrl(user, api_url) {
  if (!user || !user.profilepic) {
    // Placeholder image si aucune donnée utilisateur
    return `${api_url}/uploads/profilepics/ppplaceholder.jpeg`;
  }

  const profilepic = user.profilepic;

  if (profilepic.startsWith("d:")) {
    // Discord profile picture
    const discordHash = profilepic.substring(2); // Delete suffix
    return `https://cdn.discordapp.com/avatars/${user.discordUserId}/${discordHash}`;
  } else if (profilepic.startsWith("g:")) {
    // Google profile picture
    const googleId = profilepic.substring(2);
    return `https://lh3.googleusercontent.com/a-/AOh14G${googleId}`;
  } else {
    // Local profile picture
    return `${api_url}/uploads/profilepics/${profilepic}`;
  }
}
