import React, { useState, useEffect, useRef } from "react";
import updatePageHead from "../../../../utils/helpers/updatePageHead";
import { translateXMinus100 } from "../../../../utils/animations/animations";
import { animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCalendarPlus,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../../utils/contexts/userContext";
import { useEvent } from "../../../../utils/contexts/eventContext";
import { retreiveLocation } from "../../../../utils/helpers/retreiveLocation";
import { uploadEventPicture } from "../../../../utils/fetchs/imageFetch";
import GradientButton from "../../../Common/Buttons/GradientButton";
import MainEventInfos from "./MainEventInfos";
import DetailedEventInfos from "./DetailedEventInfos";
import SearchInput from "../../../Common/Navbar/tmpl/SearchInput";
import { UserCard } from "../../User/UserCard";
import Switch from "../../../Common/Buttons/Switch";
import { isMobile } from "react-device-detect";
import Loader from "../../../Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { GenericModal } from "../../../Modals/GenericModal/GenericModal";

export default function CreaEventCard() {
  const cssPath = "/assets/css/components/crea_event_card.css";
  const { event, setField, createEvent, updateEvent, resetEvent } = useEvent();
  const navigate = useNavigate();
  const form = useRef();

  const [showDetails, setShowDetails] = useState(false);
  const [eventConversation, setEventConversation] = useState(
    event?.event_conversation || true
  );
  const [programPubli, setProgramPubli] = useState(!!event?.program_publi_date);
  const [recurrent, setRecurrent] = useState(!!event?.recurrent);
  const [customDates, setCustomDates] = useState(null);
  const [adultsOnly, setAdultsOnly] = useState(event?.adults_only || false);
  const [showEndDate, setShowEndDate] = useState(!!event?.end_date);
  const [privateEvent, setPrivateEvent] = useState(event?.private || true);
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );

  useEffect(() => {
    const handleResize = () =>
      setIsPhone(isMobile || window.innerWidth < 767.98);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (key, value) => {
    setField(key, value);
    console.log(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedEvent = { ...event };
    console.log(updatedEvent);
    if (updatedEvent.picture instanceof File) {
      updatedEvent.picture = await uploadEventPicture(updatedEvent.picture);
    }

    const { loc, lat, lng } = await retreiveLocation(
      updatedEvent.location_formatted
    );
    updatedEvent.location_formatted = loc;
    updatedEvent.location_coordinates = [lat, lng];

    if (event._id) {
      await updateEvent(updatedEvent);
    } else {
      await createEvent(updatedEvent);
    }

    navigate(`/events`);
  };

  const handleRemoveGuest = (guestId) => {
    setField(
      "guest_list",
      event.guest_list.filter((guest) => guest._id !== guestId)
    );
  };

  if (!event) {
    return <Loader />;
  }

  return (
    <>
      {updatePageHead({ cssPath })}
      <animated.div style={translateXMinus100()} className="event_card">
        <div className="flex_between">
          <h2 className="text_gradient">
            {event._id ? "Modifier" : "Créer"} votre évènement
          </h2>
          {event.title && (
            <GenericModal
              text="Êtes-vous sûr de vouloir supprimer cet événement ?"
              onConfirm={() => resetEvent()}
            />
          )}
        </div>
        <form onSubmit={handleSubmit} ref={form} className="creation_card">
          <MainEventInfos
            eventData={event}
            setShowEndDate={setShowEndDate}
            showEndDate={showEndDate}
            onChange={handleChange}
          />
          {showDetails && (
            <DetailedEventInfos
              eventData={event}
              setEventConversation={setEventConversation}
              privateEvent={privateEvent}
              setPrivateEvent={setPrivateEvent}
              eventConversation={eventConversation}
              onChange={handleChange}
            />
          )}
          {showDetails && privateEvent && (
            <>
              <div className="event_invite">
                <p>Inviter des amis :</p>
                <SearchInput
                  data={event}
                  setData={(key, value) => handleChange(key, value)}
                  filter="user"
                  showGlass={false}
                  showFilter={false}
                />
              </div>
              {event.guest_list.filter((guest) => guest._id).length > 0 && (
                <p>Invités :</p>
              )}
              {event.guest_list
                .filter((guest) => guest._id)
                .map((guest) => (
                  <div className="guest_infos" key={guest._id}>
                    <div className="guest">
                      <UserCard user={guest} link={false} />
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="icon"
                      onClick={() => handleRemoveGuest(guest._id)}
                    />
                  </div>
                ))}
            </>
          )}
          {showDetails && (
            <>
              <div className="event_card_wrapper">
                <Switch
                  label="Programmer la publication :"
                  value={programPubli}
                  setValue={(value) => {
                    setProgramPubli(value);
                    handleChange("program_publi_date", value);
                  }}
                  tooltip="Si vous ne spécifiez aucune date, l'événement sera publié au moment de la création."
                />
                <Switch
                  label="Réservé aux majeurs :"
                  value={adultsOnly}
                  setValue={(value) => {
                    setAdultsOnly(value);
                    handleChange("adults_only", value);
                  }}
                  tooltip="Les événements réservés aux majeurs nécessitent aux utilisateurs de vérifier leur profil Wive pour pouvoir participer."
                />
                <Switch
                  label="Événement récurrent :"
                  value={recurrent}
                  setValue={(value) => {
                    setRecurrent(value);
                    handleChange("recurrent", value);
                  }}
                  tooltip="Activez cette option si cet événement est amené à se répéter régulièrement, comme une réunion hebdomadaire ou un événement mensuel."
                />
              </div>
            </>
          )}
          <div className="event_card_wrapper">
            <button
              type="button"
              className="details_btn"
              onClick={() => setShowDetails(!showDetails)}
            >
              <FontAwesomeIcon
                icon={showDetails ? faMinus : faPlus}
                className="icon"
              />
              {showDetails ? "Moins de détails" : "Plus de détails"}
            </button>
            <GradientButton
              icon={faCalendarPlus}
              text={event._id ? "Modifier votre event" : "Créer votre évent"}
              padding={"0px 30px"}
            />
          </div>
        </form>
      </animated.div>
    </>
  );
}
