import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import {
  faPaperPlane,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";
import {
  checkLogin,
  sendPasswordResetEmail,
} from "../../../utils/fetchs/authFetchs";
import { useLocation } from "react-router-dom";
import { translateXMinus30 } from "../../../utils/animations/animations";
import GradientButton from "../../../components/Common/Buttons/GradientButton";

export default function Login() {
  const { user, login, logout } = useUser();
  const animation = translateXMinus30();

  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const needGoToFeed = queryParams.get("feed") === "true" || null;
  const tokenExpired = queryParams.get("tk") === "true" || null;

  useEffect(() => {
    if (tokenExpired && user.email) {
      setInputErrorMessage(
        "Votre session a expiré. Veuillez vous reconnecter pour continuer"
      );
    }
  }, [tokenExpired]);

  useEffect(() => {
    setEmail(user.email);
  }, [user]);

  function validateLogin() {
    if (!isForgotPassword) {
      if (user.email && !password) {
        setInputErrorMessage("Veuillez entrer votre mot de passe.");
        return false;
      } else if (!user.email && (!email || !password)) {
        setInputErrorMessage("Veuillez remplir tous les champs.");
        return false;
      }
    } else {
      if (!email) {
        setInputErrorMessage("Veuillez entrer votre adresse mail.");
        return false;
      }
    }
    return true;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateLogin()) {
      if (isForgotPassword) {
        const result = await sendPasswordResetEmail(email);
        if (result.error) {
          setInputErrorMessage(result.error);
        } else {
          setInputErrorMessage(
            "Un email de réinitialisation a été envoyé à votre adresse email."
          );
        }
      } else {
        const sessionInfos = await checkLogin(
          email ? email : user.email,
          password
        );
        if (sessionInfos == 400) {
          setInputErrorMessage("Vos identifiants sont incorrects.");
          return;
        }

        login(sessionInfos.user, sessionInfos.token);
        needGoToFeed ? navigate("/") : history.back();
      }
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleLogin(e)}>
        {!user ||
          (!user.email && (
            <animated.div style={animation} className="login_input">
              <input
                type="text"
                name="mail"
                placeholder="Votre adresse mail..."
                value={email}
                autoCapitalize="off"
                onChange={(e) => setEmail(e.target.value)}
              />
            </animated.div>
          ))}
        {!isForgotPassword && (
          <animated.div style={animation} className="login_input">
            <input
              type="password"
              name="password"
              autoCapitalize="off"
              placeholder="Votre mot de passe..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </animated.div>
        )}

        <animated.div style={animation} className="btn_group">
          {inputErrorMessage && (
            <p className="error_message">{inputErrorMessage}</p>
          )}
          {!isForgotPassword && (
            <p className="fields">* Tous les champs sont obligatoires</p>
          )}
          <GradientButton
            icon={isForgotPassword ? faPaperPlane : faRightToBracket}
            text={isForgotPassword ? "Envoyer le mail" : "Connexion"}
            width={"70%"}
            textfirst={true}
            type="submit"
          />
          {!isForgotPassword && (
            <>
              <p
                className="forgot_password"
                onClick={() => setIsForgotPassword(true)}
              >
                Mot de passe oublié
              </p>
              {user && user.email && (
                <p className="change_acc_btn" onClick={() => logout()}>
                  Se connecter a un autre compte
                </p>
              )}
            </>
          )}
          {isForgotPassword && (
            <p
              className="forgot_password"
              onClick={() => setIsForgotPassword(false)}
            >
              Retour
            </p>
          )}
        </animated.div>
      </form>
    </>
  );
}
