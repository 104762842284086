import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareMediasCard({ event }) {
  const [copied, setCopied] = useState(false);

  const url = `https://wive.fr/event/${event._id}`;
  const handleCopy = () => {
    setCopied(true);
  };

  return (
    <>
      <div className="share_medias">
        <ul className="share_medias_list">
          <TwitterShareButton
            url={url}
            quote="Hey 👋, j'ai trouvé cet event sur Wive, ça devrait vous plaire ! 😉"
            hashtag={"#wive"}
          >
            <TwitterIcon size={24} round />
          </TwitterShareButton>
          <EmailShareButton
            url={url}
            body="Hey 👋, j'ai trouvé cet event sur Wive, ça devrait vous plaire ! 😉"
          >
            <EmailIcon size={24} round />
          </EmailShareButton>
          <FacebookShareButton url={url} hashtag={"#wive"}>
            <FacebookIcon size={24} round />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            url={url}
            redirectUri={"https://wive-app.fr/"}
          >
            <FacebookMessengerIcon size={24} round />
          </FacebookMessengerShareButton>
          <LinkedinShareButton url={url} source="wive">
            <LinkedinIcon size={24} round />
          </LinkedinShareButton>
          <PinterestShareButton url={url}>
            <PinterestIcon size={24} round />
          </PinterestShareButton>
          <RedditShareButton url={url}>
            <RedditIcon size={24} round />
          </RedditShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={24} round />
          </TelegramShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={24} round />
          </WhatsappShareButton>
        </ul>
        <div className="share_media_flex">
          <input value={url} readOnly />
          <CopyToClipboard text={url} onCopy={handleCopy}>
            <p>{copied ? "Copié ✅" : "Copier le lien"}</p>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
}
