import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserList } from "../../../Modals/UserList/UserList";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import {
  createFollowRequest,
  unfollow,
} from "../../../../utils/fetchs/followRequestFetchs";
import { useUser } from "../../../../utils/contexts/userContext";

export default function ParticpantsCard({ participants, participantCount }) {
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  const { user } = useUser();

  const handleFollow = async (toUser) => {
    console.log(toUser);
    const followExist = await unfollow(user, toUser);
    // If unfollow returns nothing then we want to follow
    if (!followExist) {
      await createFollowRequest(user, toUser);
    }
  };
  return (
    <>
      <div className="flex_column">
        <UserList
          title="Participants"
          userList={participants}
          shouldBeOpen={shouldBeOpen}
          buttonAction={handleFollow}
          setShouldBeOpen={setShouldBeOpen}
        />
        <p className="event_participants" onClick={() => setShouldBeOpen(true)}>
          <FontAwesomeIcon icon={faUsers} className="icon" />
          {participantCount} participants
        </p>
        <ParticipatingFriendList participants={participants} />
      </div>
    </>
  );
}
